a {
    text-decoration: none !important;
}
.car-page-section-one {
    /* background-color: pink; */
    padding: 50px 50px 0px 50px;
    overflow: hidden;
}

.details-section {
    /* background-color: lightgreen; */
}
.deposit {
    margin-bottom: 3px;
}

.car-page-header {
    padding-left: 30px;
}

.car-cards-details-container {
    display: flex;
    /* background-color: lime; */
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.car-filters-container {
    /* background-color: lime; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.car-filters {
    /* background-color: lightblue;  */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1rem 5px 1rem 5px;
    border-radius: 10px;
    border: 2px solid #3f71b9;
    position: relative;
}

.car-page-filter-links {
    /* background-color: pink; */
    width: 250px;
    text-align: center;
    margin: 5px;
    padding: 4px;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    text-decoration: none;
    color: black !important;
}

.car-page-filter-btn {
    position: absolute;
    bottom: -15px;
    border-radius: 30px;
    padding: 1px 10px;
}

.car-page-section-two {
    padding: 30px 50px;
}

.filterSideBarBtn {
    padding: 7px;
    border-radius: 7px;
    cursor: pointer;
    border: none;
    outline: none;
    width: 100px;
    color: white;
    position: fixed;
    bottom: 3%;
    left: 50%;
    transform: translate(-50%, 0%);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: none;
    z-index: 1;
}


/* Toogle Filter Sidebar CSS */
.mobile-filter-sidebar {
    /* background-color: pink; */
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0%;
    left: -100%;
    /* left: 0%; */
    z-index: 1;
    display: flex;
}

.active-filter-sidebar {
    transition: all 0.2s ease-out;
    top: 0%;
    left: 0%;
}

.mobile-sidebar-container {
    background-color: white;
    width: 90%;
    height: 100%;
}

.filter-sidebar-close-vertical-bar {
    width: 10%;
    height: 100%;
}

.mobile-filter-sidebar-header {
    /* background-color: lightcoral; */
    display: flex;
    align-items: center;
    position: relative;
}

.mobile-filter-sidebar-header-logo {
    height: 40px;
    width: 40px;
    margin: 20px 10px;
}

.mobile-filter-sidebar-header h4 {
    margin-bottom: 0px;
}

.mobile-filter-sidebar-close-icon {
    font-size: 35px;
    color: gray;
    position: absolute;
    right: 5%;
}

.mobile-filter-sidebar-body {
    /* background-color: blue; */
    overflow-y: scroll;
    height: 90%;
}

@media screen and (max-width:1050px) {
    .details-section{
        width: 100%;
    }
    #car-page-filter-section {
        display: none;
    }

    .car-filters-container {
        /* display: none; */
    }

    .car-page-section-one {
        padding: 20px 10px;
        overflow: hidden;
    }

    .filterSideBarBtn {
        display: block;
    }
    .slick-slider{
        overflow: hidden;
    }
}